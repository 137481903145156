import React from "react";
import "./Home.css";
import Product from "./Product";

function Home() {
  return (
    <div className="home">
      <div className="home__container">
        <img
          className="home__image"
          src="https://upload.wikimedia.org/wikipedia/commons/3/31/AT%26T_logo_2016.svg"
          alt=""
        />

        <div className="home__row">
          <Product
            id="12321341"
            title="iPhone 12 Pro Max"
            price={1399.99}
            rating={5}
            image="https://www.att.com/idpassets/global/devices/phones/apple/apple-iphone-12-pro-max/defaultimage/Pacific%20Blue-hero-zoom.png"
          />
          <Product
            id="49538094"
            title="Galaxy S21 Ultra 5G"
            price={1199.99}
            rating={4}
            image="https://www.att.com/idpassets/global/devices/phones/samsung/samsung-galaxy-s21-ultra-5g/defaultimage/phantom-black-hero-zoom.png"
          />
        </div>

        <div className="home__row">
          <Product
            id="4903850"
            title="iPhone 12"
            price={799.99}
            rating={3}
            image="https://www.att.com/idpassets/global/devices/phones/apple/apple-iphone-12/carousel/product-red/64gb/6860C-hero-red_carousel.png"
          />
          <Product
            id="23445930"
            title="iPhone SE (2020)"
            price={399.99}
            rating={4}
            image="https://www.att.com/idpassets/global/devices/phones/apple/iphone-se-2020/defaultimage/Black-hero-zoom.png"
          />
          <Product
            id="3254354345"
            title="iPad Pro 12.9-inch 4th generation"
            price={1149.99}
            rating={4}
            image="https://www.att.com/catalog/en/idse/Apple/Apple%20iPad%20Pro%2012_9-inch%204th%20generation/Space%20Gray-hero-zoom.png"
          />
        </div>

        <div className="home__row">
          <Product
            id="90829332"
            title="Galaxy Tab S7 5G"
            price={849.99}
            rating={4}
            image="https://www.att.com/idpassets/global/devices/tablets/samsung/samsung-galaxy-tab-s7-5g/defaultimage/mystic-black-hero-zoom.png"
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
